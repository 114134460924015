import React from 'react';
import addPromoImg from "../../theme/img/finance-sale.png";
import civilScore from "../../theme/img/civil-score.png";
import {IonCol, IonRow} from "@ionic/react";
// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y,Autoplay,FreeMode  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import {  useHistory } from 'react-router-dom';

const SliderComponent = React.memo(function SampleComponent() {
    const history = useHistory();
    const goToPage = (type)=>{
        history.push(type);
    }
    return (
        <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y,Autoplay,FreeMode]}
            pagination={{ clickable: true }}>
            <SwiperSlide>
                <div className="main_header_section_second_header">
                    <div className="promotion_add_header_section">
                        <IonRow>
                            <IonCol className={"new_logo_promo_img_section"}>
                               <img className='promotion_add_header_section_img_1' src={addPromoImg}/>
                            </IonCol>
                            <IonCol>
                                <div className='promotion_header_text_section'>
                                    <span className='loan_text_span_1'>Look for fatafat loan...</span><br></br>
                                    <span className='loan_text_span_2'>Apna finance hai na...!!!</span><br></br><br></br>
                                    <span className='loan_text_span_3'>Check your loan eligibility in just few click and apply for loan... free of cost</span><br></br>
                                    <button onClick={()=>goToPage('/loan-application/personal-loan')} className='apply_loan_button'>Apply Now</button>
                                </div>
                            </IonCol>
                        </IonRow>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="main_header_section_second_header">
                    <div className="promotion_add_header_section">
                        <IonRow>
                            <IonCol className={"new_logo_promo_img_section"}>
                                <img className='promotion_add_header_section_img_1' src={civilScore}/>
                            </IonCol>
                            <IonCol>
                                <div className='promotion_header_text_section'>
                                    <span className='loan_text_span_1'>Check your credit score free</span><br></br>
                                    <span className='loan_text_span_2'>You can download your credit score.</span><br></br><br></br>
                                    <span className='loan_text_span_3'>Your credit report will securely sent to given email address with password protection.</span><br></br>
                                    <button  onClick={()=>goToPage('/check-civil-report')}  className='apply_loan_button'>Check Now</button>
                                </div>
                            </IonCol>
                        </IonRow>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    )
})
export default SliderComponent;