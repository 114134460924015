import React from 'react';
import { useParams } from 'react-router-dom';
import HomeLoanComponent from "./HomeLoanComponent";
import PersonalLoanComponent from "./PersonalLoanComponent";
import BusinessLoanComponent from "./BusinessLoanComponent";
import CarLoanComponent from "./CarLoanComponent";
import MicroLoanComponent from "./MicroLoanComponent";
import PropertyLoanComponent from "./PropertyLoanComponent";

const LoanApplicationFormComponent = React.memo(function LoanApplicationFormComponent() {
    const {loanType} = useParams();

    const renderLoanComponentByType = (type)=>{
        switch (type){
            case 'home-loan':
                return <HomeLoanComponent/>
            case 'personal-loan':
                return <PersonalLoanComponent/>
            case 'business-loan':
                return <BusinessLoanComponent/>
            case 'car-loan':
                return <CarLoanComponent/>
            case 'micro-loan':
                return <MicroLoanComponent/>
            case 'property-loan':
                return <PropertyLoanComponent/>
        }
    }

    return (
        <>
            {renderLoanComponentByType(loanType)}
        </>
    )
})
export default LoanApplicationFormComponent;