import React from 'react';
import {useStoreState} from "../../provider/StateProvider";
import loaderWaiting from '../../theme/img/loader-wait.gif';
import checkMark from '../../theme/img/check-mark.gif';
import cloneDeep from "lodash/cloneDeep";

const ApplicationSubmitPopupComponent = React.memo(function ApplicationSubmitPopupComponent() {
    const {applicationSubmitPopupSuccess,setApplicationSubmitPopupSuccess} = useStoreState();
    const {loading,isOpen,message,body} = applicationSubmitPopupSuccess;

    const closePopup = ()=>{
        applicationSubmitPopupSuccess.isOpen = false;
        applicationSubmitPopupSuccess.loading = false;
        applicationSubmitPopupSuccess.message = '';
        applicationSubmitPopupSuccess.body = '';
        setApplicationSubmitPopupSuccess(cloneDeep(applicationSubmitPopupSuccess))
    }
    return (
        <>
        {(isOpen) ?
            <div id={"show_success_popup_main_section"} className={"show_success_popup_main_section"}>
                <div className={"success_popup_inner_div"}>
                    <div className={"success_popup_header"}>
                        Loan Application
                    </div>
                    <div className={"success_popup_main_body"}>
                        <div className={"success_popup_icon_section"}>
                            {(loading) ?
                                <div className={"success_popup_icon_section_loading"}>
                                  <img src={loaderWaiting}/>
                                </div>
                                :
                                <div className={"success_popup_icon_section_success"}>
                                  <img src={checkMark}/>
                                </div>
                            }
                        </div>
                        <div className={"success_popup_message_section"}>{message}</div>
                        {(body) ?
                            <div className={"success_popup_body_message_section"}>{body}</div>
                            : ''
                        }
                        {(!loading) ?
                            <button onClick={closePopup} className={"close_popup_button"}>CLOSE</button>
                            : ''
                        }
                    </div>
                </div>
            </div>
            : ''
        }
        </>
    )
})
export default ApplicationSubmitPopupComponent;