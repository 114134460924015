import React from 'react';
import logoImg from "../../theme/img/logo.png";
import mobileNumberLogo from "../../theme/img/logo_mobile_img.png";
import {useStoreState} from "../../provider/StateProvider";

const LoginMobileNumberPage = React.memo(function LoginMobileNumberPage({userEnteredMobileNumber,setUserEnteredMobileNumber,setVerifyLoginOtpRequest}) {

  const {actionToSendOtpVerificationToUserMobileNumber} = useStoreState();
    const callFunctionToSendOtpInMobileNumber = ()=>{
        setVerifyLoginOtpRequest(true);
        if(userEnteredMobileNumber?.trim()?.length === 10){
            const mobile = `+91${userEnteredMobileNumber}`;
            actionToSendOtpVerificationToUserMobileNumber(mobile);
        }
    }

    return (
        <div className={"col-md-7"}>
            <div className={"login_page_right_side_container"}>
                <div className={"login_page_site_logo_section"}>
                    <div className='logo_and_title'>
                        <img src={logoImg} className="site_logo_image"/>
                        <div className="logo_section">
                            <div className="first">apna</div>
                            <div className="second">Finance</div>
                        </div>
                    </div>
                </div>
                <div className={"login_title_section"}>Signup Or Login To Your Account</div>
                <div className={"login_title_section_mobile_number_image"}>
                    <img src={mobileNumberLogo}/>
                </div>
                <div className={"login_title_section_input_mobile"}>
                    <form autoComplete="off">
                        <div className="form_group fullCon flag">
                            <span className="input_flag"> +91 </span>
                            <input className="custom_input"
                                   pattern="[0-9]*" type="tel"
                                   onChange={(e)=>setUserEnteredMobileNumber(e.target.value)}
                                   placeholder="Mobile Number"
                                   autoFocus={true}
                                   validations="required,mobile,minlen:10:Mobile number should contain atleast 10 digits,maxlen:10"
                                   value={userEnteredMobileNumber}
                                   name="mobileNo"/>
                            <span className="_line p-R dB fullCon"></span>
                        </div>
                    </form>
                </div>
                <div className={"login_title_section_input_mobile_button"}>
                    <button onClick={callFunctionToSendOtpInMobileNumber} className={"login_button"}>Login with OTP</button>
                </div>
                <div className={"login_title_section_term_of_use_section"}>
                    By logging in, you agree to the following
                </div>
                <div className={"login_title_section_term_of_use_section_2"}>
                    Credit Report Terms of Use and Terms of Use
                </div>
            </div>
        </div>
    )
})
export default LoginMobileNumberPage;