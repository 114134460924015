import {IonContent,IonPage} from '@ionic/react';
import React, {useEffect} from "react";
import CreditScoreDataComponent from "../components/CheckCreditScoreComponents/CreditScoreDataComponent";
import SubHeaderComponent from "../components/HeaderComponents/SubHeaderComponent";
import CreditScoreDataComponentMobile from "../components/CheckCreditScoreComponents/CreditScoreDataComponentMobile";
import {useStoreState} from "../provider/StateProvider";

const CreditScoreDataPage = () => {
    const {creditScoreData} = useStoreState();

    useEffect(()=>{
      if(creditScoreData?.CIRReportDataLst === undefined){
          window.location.href = '/check-civil-report';
      }
    },[])

    return (
        <IonPage>
            <SubHeaderComponent/>
            <IonContent>
                <div className={'loan_calculator_page_container not_to_display_in_phone'}>
                   <CreditScoreDataComponent/>
                </div>
                <div className={'loan_calculator_mobile_view_page_container not_to_display_in_desktop'}>
                    <div className="main_section_credit_score_page_data">
                        <CreditScoreDataComponentMobile/>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default CreditScoreDataPage;
