import React, {useState} from "react";
import {handleMortgageDataChange} from "../LoginComponents/utils";
import {PaymentGraph} from "../LoginComponents/PaymentGraph";
import {cloneDeep} from "lodash/lang";
import {useHistory} from "react-router-dom";

const LoanCalculatorDesktop = React.memo(function LoanCalculatorDesktop() {

    const localeOptions = {
        style:'currency',
        currency:'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }
    const history = useHistory();
    const goBack = ()=>{
        history.goBack();
    }
    const [loanType, setLoanType] = useState('home');

    const [minMaxRateOfLoanAmount, setMinMaxRateOfLoanAmount] = useState({min:0,max:2000000000});
    const [bottomRangeValueLoanAmount, setBottomRangeValueLoanAmount] = useState([0,50,100,150,200]);

    const [minMaxInterestRate, setMinMaxInterestRate] = useState({min:5,max:20});
    const [bottomRangeValueInterestRate, setBottomRangeValueInterestRate] = useState([5,7.5,10,12,5,15,17.5,20]);

    const [minMaxLoanTenure, setMinMaxLoanTenure] = useState({min:0,max:30});
    const [bottomRangeValueLoanTenure, setBottomRangeValueLoanTenure] = useState([0,5,10,15,20,25,30]);

    const [amountToBorrow, setAmountToBorrow] = useState(500000);
    const [mortgageTerm, setMortgageTerm] = useState(5);
    const [interestRate, setInterestRate] = useState(5);

    //Set initial values for the whole mortgage term
    const monthlyPayment = ((interestRate / 100 / 12) * amountToBorrow) / (1 - (Math.pow((1 + (interestRate / 100 / 12)),((0 - mortgageTerm) * 12))));
    const totalRepaid = monthlyPayment * 12 * mortgageTerm;
    const totalInterestPaid = totalRepaid - amountToBorrow;
    const yearlyPayments = handleMortgageDataChange(amountToBorrow, mortgageTerm, interestRate, monthlyPayment);
    const calculatePaymentEmi = (principal ,interestRate,year)=>{
        let interest = parseFloat(interestRate) / 100 / 12;
        let payments = parseFloat(year) * 12;
        let x = Math.pow(1 + interest, payments); //Math.pow computes powers
        let monthly = (principal*x*interest)/(x-1);
        return monthly;
    }
    React.useEffect(()=>{
        if(loanType === 'home'){
            setMinMaxRateOfLoanAmount(cloneDeep({min:0,max:200000000}));
            setBottomRangeValueLoanAmount([0,50,100,150,200]);
            setAmountToBorrow(500000);

            setMinMaxInterestRate(cloneDeep({min:5,max:20}));
            setBottomRangeValueInterestRate([5,7.5,10,12,5,15,17.5,20]);
            setInterestRate(5);

            setMinMaxLoanTenure(cloneDeep({min:5,max:30}));
            setBottomRangeValueLoanTenure([5,10,15,20,25,30]);
            setMortgageTerm(5);

        }else if(loanType === 'car'){
            setMinMaxRateOfLoanAmount(cloneDeep({min:0,max:2000000}));
            setBottomRangeValueLoanAmount([0,5,10,15,20]);
            setAmountToBorrow(100000);

            setMinMaxInterestRate(cloneDeep({min:5,max:20}));
            setBottomRangeValueInterestRate([5,7.5,10,12,12.5,15,17.5,20]);
            setInterestRate(5);

            setMinMaxLoanTenure(cloneDeep({min:1,max:7}));
            setBottomRangeValueLoanTenure([1,2,3,4,5,6,7]);
            setMortgageTerm(2);
        }else{
            setMinMaxRateOfLoanAmount(cloneDeep({min:0,max:3000000}));
            setBottomRangeValueLoanAmount([0,5,10,15,20,25,30]);
            setAmountToBorrow(100000);

            setMinMaxInterestRate(cloneDeep({min:5,max:25}));
            setBottomRangeValueInterestRate([5,7.5,10,12,5,15,17.5,22.5,25]);
            setInterestRate(7.5);

            setMinMaxLoanTenure(cloneDeep({min:1,max:5}));
            setBottomRangeValueLoanTenure([1,2,3,4,5]);
            setMortgageTerm(2);
        }
    },[loanType])

    const getLeftStyle = (length,key)=>{
        let gap = 100/(length-1);
        return `${gap*key}%`;
    }

    return (

        <div className={"row"}>
            <div className={"col-md-5"}>
                <div className={"loan_calculator_page_container_left_side_inner_section"}>
                    <div className={"left_side_footer_graph_section"}>
                        <PaymentGraph
                            mortgageTerm={mortgageTerm}
                            yearlyPayments={yearlyPayments}
                        />
                    </div>
                    <div className={"table_column_display_price_div_section"}>
                        <div className={"table_column_display_price_div_section_inner row"}>
                            <div className={"left_side_text col"}>Loan Emi</div>
                            <div className={"right_side_text col"}>{parseInt(calculatePaymentEmi(amountToBorrow,interestRate,mortgageTerm).toString()).toLocaleString('en-GB', localeOptions)}</div>
                        </div>
                        <div className={"table_column_display_price_div_section_inner row"}>
                            <div className={"left_side_text col"}>Loan Amount</div>
                            <div className={"right_side_text col"}>{parseInt(amountToBorrow.toString()).toLocaleString('en-GB', localeOptions)}</div>
                        </div>
                        <div className={"table_column_display_price_div_section_inner row"}>
                            <div className={"left_side_text col"}>Total Interest Payable</div>
                            <div className={"right_side_text col"}>{parseInt(totalInterestPaid.toString()).toLocaleString('en-GB', localeOptions)}</div>
                        </div>
                        <div className={"table_column_display_price_div_section_inner row"}>
                            <div className={"left_side_text col"}>Total Payment</div>
                            <div className={"right_side_text col"}>{parseInt(totalRepaid.toString()).toLocaleString('en-GB', localeOptions)}</div>
                        </div>
                    </div>
                    {/*<div className={"left_side_footer_text_section"}>*/}
                    {/*   *Your actual rate, payment and cost may differ from the values above.*/}
                    {/*    Its depends upon the offers given by you loan provider bank**/}
                    {/*</div>*/}
                </div>
            </div>
            <div className={"col-md-7"}>
                <div className={"loan_calculator_page_container_right_side_section"}>
                    <div className={"loan_calculator_right_side_heading_mobile"}>
                        LOAN CALCULATOR
                        <div className={"right_side_sub_header"}>
                            <div onClick={goBack} className={"back_button"}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.151 219.151"><path d="M109.576 219.151c60.419 0 109.573-49.156 109.573-109.576S169.995 0 109.576 0 .002 49.156.002 109.575s49.155 109.576 109.574 109.576zm0-204.151c52.148 0 94.573 42.426 94.574 94.575s-42.425 94.575-94.574 94.576-94.573-42.427-94.573-94.577S57.428 15 109.576 15zM94.861 156.507c2.929 2.928 7.678 2.927 10.606 0a7.5 7.5 0 0 0-.001-10.608l-28.82-28.819 83.457-.008a7.5 7.5 0 1 0-.001-15l-83.46.008 28.827-28.825a7.5 7.5 0 0 0 0-10.607 7.48 7.48 0 0 0-5.304-2.197c-1.919 0-3.838.733-5.303 2.196l-41.629 41.628c-1.407 1.406-2.197 3.313-2.197 5.303a7.51 7.51 0 0 0 2.198 5.305l41.627 41.624z"/></svg>
                                &nbsp;Go Back
                            </div>
                        </div>
                    </div>
                    <div className={"loan_calculator_page_container_right_side_section_inner"}>
                        <div className={"loan_input_container"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <button type={"button"} onClick={()=>setLoanType('home')} className={"loan_type_button "+(loanType === 'home' ? 'active' : '')}>HOME LOAN</button>
                                </div>
                                <div className={"col"}>
                                    <button type={"button"} onClick={()=>setLoanType('car')} className={"loan_type_button "+(loanType === 'car' ? 'active' : '')}>CAR LOAN</button>
                                </div>
                                <div className={"col"}>
                                    <button type={"button"} onClick={()=>setLoanType('personal')} className={"loan_type_button "+(loanType === 'personal' ? 'active' : '')}>PERSONAL LOAN</button>
                                </div>
                            </div>
                        </div>
                        <div className={"loan_input_container"}>
                            <div className={"loan_calculator_input_label"}>
                                Loan Amount
                            </div>
                            <div className={"loan_calculator_input_text"}>
                                {parseInt(amountToBorrow.toString()).toLocaleString('en-GB', localeOptions)}
                            </div>
                            <div className={"loan_calculator_input_text_slider"}>
                                <input
                                    type="range"
                                    className="grid__item--range-slider"
                                    id="purchasingHousePrice"
                                    min={minMaxRateOfLoanAmount?.min}
                                    max={minMaxRateOfLoanAmount?.max}
                                    value={amountToBorrow}
                                    onChange={(event) =>
                                        setAmountToBorrow(event.target.value)
                                    } />
                                <div className={"slider_amount_range"}>
                                    {(bottomRangeValueLoanAmount?.map((val,key)=>(
                                        <div key={key} style={{left:getLeftStyle(bottomRangeValueLoanAmount?.length,key)}} className={"slider_amount_range_col"}>{val}L</div>
                                    )))}
                                </div>
                            </div>
                        </div>
                        <div className={"loan_input_container"}>
                            <div className={"loan_calculator_input_label"}>
                                Loan Tenure
                            </div>
                            <div className={"loan_calculator_input_text"}>
                                {interestRate}%
                            </div>
                            <div className={"loan_calculator_input_text_slider"}>
                                <input
                                    type="range"
                                    className="grid__item--range-slider"
                                    id="interestRate"
                                    min={minMaxInterestRate?.min}
                                    max={minMaxInterestRate?.max}
                                    value={interestRate}
                                    onChange={(event) =>
                                        setInterestRate(event.target.value)
                                    } />
                                <div className={"slider_amount_range"}>
                                    {(bottomRangeValueInterestRate?.map((val,key)=>(
                                        <div key={key} style={{left:getLeftStyle(bottomRangeValueInterestRate?.length,key)}}  className={"slider_amount_range_col"}>{val}%</div>
                                    )))}
                                </div>
                            </div>
                        </div>
                        <div className={"loan_input_container"}>
                            <div className={"loan_calculator_input_label"}>
                                Interest Rate
                            </div>
                            <div className={"loan_calculator_input_text"}>
                                {mortgageTerm} Years
                            </div>
                            <div className={"loan_calculator_input_text_slider"}>
                                <input
                                    type="range"
                                    className="grid__item--range-slider"
                                    id="mortgageTerm"
                                    min={minMaxLoanTenure?.min}
                                    max={minMaxLoanTenure?.max}
                                    value={mortgageTerm}
                                    onChange={(event) =>
                                        setMortgageTerm(event.target.value)
                                    } />
                                <div className={"slider_amount_range"}>
                                    {(bottomRangeValueLoanTenure?.map((val,key)=>(
                                        <div key={key} style={{left:getLeftStyle(bottomRangeValueLoanTenure?.length,key)}}  className={"slider_amount_range_col"}>{val}</div>
                                    )))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default LoanCalculatorDesktop;