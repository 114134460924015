import {IonContent,IonPage} from '@ionic/react';
import React from "react";
import LoanCalculatorMobile from "../components/LoanCalculatorComponents/LoanCalculatorMobile";
import LoanCalculatorDesktop from "../components/LoanCalculatorComponents/LoanCalculatorDesktop";

const LoanCalculatorPage = () => {
    return (
        <IonPage>
            <IonContent>
                <div className={'loan_calculator_page_container not_to_display_in_phone'}>
                    <LoanCalculatorDesktop/>
                </div>
                <div className={'loan_calculator_mobile_view_page_container not_to_display_in_desktop'}>
                    <LoanCalculatorMobile/>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default LoanCalculatorPage;
