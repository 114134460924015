
import { IonHeader } from '@ionic/react';
import React, {useState,useRef} from 'react';
import logoImg from '../../theme/img/logo.png'
import {  useHistory } from 'react-router-dom';
import {useStoreState} from "../../provider/StateProvider";
import UseOutsideClick from "../../hooks/UseOutsideClick";

const MainHeaderComponent = () => {
    const {userSignInInfo,logoutUserFormApp} = useStoreState();
    const [openCloseUserAction,setOpenCloseUserAction] = useState(false);
    const history = useHistory();
    const userDropdownRef = useRef();
    const openTabByType = (type)=>{
        history.push(type);
    }

    UseOutsideClick(userDropdownRef, ['#dropdown_login_account'],() => {
        if(openCloseUserAction)
            setOpenCloseUserAction(false);
    });

    return (
        <IonHeader className="ion-no-border">
            <div className={"main_header_section_header"}>
                <div className="main_header_section_header_inner_header">
                    <div className='logo_and_title'>
                        <img src={logoImg} className="site_logo_image"/>
                        <div className="logo_section">
                            <div className="first">apna</div>
                            <div className="second">Finance</div>
                        </div>
                    </div>
                    <div className="header_right_side_section">
                        {(userSignInInfo === null) ?
                            <>
                                <button onClick={() => openTabByType('/admin/admin-dashboard')} className="signin_button_section admin">Admin Panel</button>
                                <button onClick={() => openTabByType('/login-signup')} className="signin_button_section">Sign In</button>
                            </>
                            :
                            <div className="left_side_user">
                                <div ref={userDropdownRef} onClick={()=>setOpenCloseUserAction(true)} className={'user_login_avatar_icon'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M31.11 28.336c-.201-.133-3.848-2.525-9.273-3.699 1.99-2.521 3.268-5.912 3.811-8.169.754-3.128.461-9.248-2.543-13.062C21.349 1.177 18.892 0 16 0s-5.349 1.178-7.104 3.406C5.892 7.219 5.6 13.339 6.353 16.467c.543 2.257 1.82 5.648 3.811 8.169-5.425 1.174-9.072 3.566-9.272 3.699a2 2 0 0 0-.805 2.242A2 2 0 0 0 2 32h28a2 2 0 0 0 1.914-1.422c.257-.842-.071-1.754-.804-2.242zm-10.843-4.938l-.326.414c-2.385 2.74-5.495 2.74-7.879 0l-.327-.414c-2.785-3.529-4.167-8.197-3.572-12.65C8.708 6.469 11.16 2 16 2s7.293 4.47 7.838 8.749c.593 4.455-.784 9.118-3.571 12.649zM2 30c.138-.092 3.526-2.314 8.586-3.408l2.484-.537c.887.582 1.86.945 2.93.945s2.043-.363 2.93-.945l2.484.537C26.434 27.678 29.81 29.875 30 30H2z"/></svg>
                                </div>
                                <div id={"dropdown_login_account"} style={{display:openCloseUserAction ? 'block' : 'none'}} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item">Dashboard</a>
                                    <a onClick={logoutUserFormApp} className="dropdown-item">Logout</a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </IonHeader>
    );
};

export default MainHeaderComponent;
