import {IonContent,IonPage} from '@ionic/react';
import React from "react";
import DataTable from 'react-data-table-component';
import {useStoreState} from "../../provider/StateProvider";
import SubHeaderComponent from "../../components/HeaderComponents/SubHeaderComponent";

const LoanApplicationDataPage = () => {
    const {loanApplicationData} = useStoreState();
    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Created at',
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
        },
        {
            name: 'Dob',
            selector: row => row.dob,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Employment status',
            selector: row => row.employment_status,
            sortable: true,
        },
        {
            name: 'Gross turn over',
            selector: row => row.gross_turn_over,
            sortable: true,
        },
        {
            name: 'Loan amount',
            selector: row => row.loan_amount,
            sortable: true,
        },
        {
            name: 'Loan taken timeline',
            selector: row => row.loan_taken_timeline,
            sortable: true,
        },
        {
            name: 'Loan taken timeline',
            selector: row => row.loan_taken_timeline,
            sortable: true,
        },
        {
            name: 'Loan type',
            selector: row => row.loan_type,
            sortable: true,
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Primary bank name',
            selector: row => row.primary_bank_name,
            sortable: true,
        },
        {
            name: 'Property city',
            selector: row => row.property_city,
            sortable: true,
        },
    ];

    return (
        <IonPage>
            <SubHeaderComponent/>
            <IonContent>
                <div className={"loan_application_data_main_section"}>
                    <div className={"loan_application_data_table"}>Loan Application Data Table</div>
                    {(loanApplicationData?.length) ?
                        <DataTable
                            columns={columns}
                            data={loanApplicationData}
                        />
                        : ''
                    }
                </div>
            </IonContent>
        </IonPage>
    );
};

export default LoanApplicationDataPage;
