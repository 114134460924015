import { IonContent, IonPage } from '@ionic/react';
import MainHeaderComponent from '../components/HeaderComponents/MainHeaderComponent';
import HomePageComponent from '../components/HomePageComponent/HomePageComponent';
import SliderComponent from "../components/HomePageComponent/SliderComponent";
import FooterSliderComponent from "../components/HomePageComponent/FooterSliderComponent";
import React from "react";
import DownloadAppSectionComponent from "../components/HomePageComponent/DownloadAppSectionComponent";

const Home: React.FC = () => {
  return (
    <IonPage>
       <MainHeaderComponent/>
       <IonContent fullscreen>
         <SliderComponent/>
         <HomePageComponent/>
         <DownloadAppSectionComponent/>
          <div className='home_page_main_content_section'>
              <div className='inner_section_block'>
                  <div className='inner_section_block_heading'>
                      ABOUT US & CONTACT US
                  </div>
                  <FooterSliderComponent/>
              </div>
          </div>
       </IonContent>
    </IonPage>
  );
};

export default Home;
