import { IonHeader } from '@ionic/react';
import React, {useState,useRef} from 'react';
import logoImg from '../../theme/img/logo.png';
import UseOutsideClick from "../../hooks/UseOutsideClick";

const AdminMainHeaderComponent = () => {
    const userAuth = localStorage.getItem('adminAuthentication');
    const [openCloseUserAction,setOpenCloseUserAction] = useState(false);
    const userDropdownRef = useRef();
    const logoutUserFormApp = ()=>{
        localStorage.removeItem('adminAuthentication');
        window.location.reload();
    }

    UseOutsideClick(userDropdownRef, ['#dropdown_login_account'],() => {
        if(openCloseUserAction)
            setOpenCloseUserAction(false);
    });

    return (
        <IonHeader className="ion-no-border">
            <div className={"main_header_section_header"}>
                <div className="main_header_section_header_inner_header">
                    <div className='logo_and_title'>
                        <img src={logoImg} className="site_logo_image"/>
                        <div className="logo_section">
                            <div className="first">apna</div>
                            <div className="second">Finance</div>
                        </div>
                    </div>
                    <div className="header_right_side_section">
                        {(userAuth !== null) ?
                            <div className="left_side_user">
                                <button onClick={logoutUserFormApp} className="signin_button_section">Logout</button>
                            </div>
                            :''
                        }
                    </div>
                </div>
            </div>
        </IonHeader>
    );
};

export default AdminMainHeaderComponent;
