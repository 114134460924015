import React, {useState} from 'react';
import loanApplicationImg from "../../theme/img/home_loan.png";
import {useStoreState} from "../../provider/StateProvider";
import $ from "jquery";

const AddBankLoanWithInterestComponent = React.memo(function AddBankLoanWithInterestComponent() {

    const loanTypeOptionsList = [
      'Car Loan',
      'Bike Loan',
      'Home Loan',
      'Property Loan',
      'Personal Loan',
    ];

    const {allBankListData,actionToInsertBankLoanList} = useStoreState();
    const [bank,setBank] = useState({});
    const [loanType,setLoanType] = useState('');
    const [interestRate,setInterestRate] = useState('');

    const resetBankOfferForm = ()=>{
        setBank({});
        setLoanType('');
        setInterestRate('');
    }

    const callFunctionToInsertBankLoanList = ()=>{
        if(validateForm()){
            let payload = {
                bank,
                loanType,
                interestRate
            }
            actionToInsertBankLoanList(payload,resetBankOfferForm);
        }
    }

    const validateForm = ()=>{
        if(!Object?.keys(bank).length){
            return false;
        }else if(!interestRate){
            return false;
        }else if(!loanType?.trim()?.length){
            return false;
        }else{
            return true;
        }
    }

    React.useEffect(()=>{
        $(function () {
            $('.js-example-basic-single').select2();
            $('.js-example-basic-single').on('select2:select', function (e) {
                if(e.target.name === 'bank'){
                    setBank(e.target.value)
                }else  if(e.target.name === 'loan-type'){
                    setLoanType(e.target.value)
                }
            });
        });
    },[])

    return (
        <div className="main_header_section_second_header loan_page_application home_loan">
            <div className="promotion_add_header_section">
                <div className={"application_header_heading"}>
                    ADD NEW BANK LOAN OFFER
                </div>
                <div className="row">
                    <div className={"col-md-6"}>
                        <div className={"new_logo_promo_img_section"}>
                            <img className='promotion_add_header_section_img_1' src={loanApplicationImg}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='promotion_header_text_section_loan_page_form'>
                            <form autoComplete={"off"}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="pwd">Select Bank:</label>
                                            <select
                                                onChange={(e)=>setBank(e.target.value)} value={bank?.name}
                                                required className="js-example-basic-single select form-control" name="bank">
                                                <option className={"initial_option"}  value="">Select bank</option>
                                                {allBankListData?.map((bank,key)=>(
                                                    <option key={key} value={bank?.id}>{bank?.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="pwd">Select Loan Type:</label>
                                            <select onChange={(e)=>setLoanType(e.target.value)} value={loanType}
                                                    className="js-example-basic-single select form-control" name="loan-type">
                                                   <option className={"initial_option"}  value="">Select loan type</option>
                                                    {loanTypeOptionsList?.map((loan,key)=>(
                                                        <option key={key} value={loan}>{loan}</option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="mobile">Interest Rate:</label>
                                            <div className={"input-group"}>
                                                <span className="input-group-addon">%</span>
                                                <input type="number" value={interestRate}
                                                       onChange={(e)=>setInterestRate(e.target.value)}
                                                       className="form-control"
                                                       placeholder="7"
                                                       id="mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div onClick={callFunctionToInsertBankLoanList} className={"submit_form_button"}>
                                    <button type={"button"} disabled={!validateForm()} className={"btn "+(validateForm() ? '' : 'cursor-block')}>
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default AddBankLoanWithInterestComponent;