import React, {useEffect, useState} from 'react';
import {useStoreState} from "../../provider/StateProvider";
import creditLogo from "../../theme/img/credit-image.png";
import CreditScoreDataComponentMobile from "./CreditScoreDataComponentMobile";

const CreditScoreDataComponent = React.memo(function CreditScoreDataComponent() {
    const {creditScoreData} = useStoreState();

    return (
        <>
            {(creditScoreData?.CIRReportDataLst?.length) ?
                 <div className="main_section_credit_score_page_data">
                        <div className={"row"}>
                            <div className={"col-md-5"}>
                                <div className={"credit_score_main_section_left_side"}>
                                    <img className={"credit_section_image"} src={creditLogo}/>
                                </div>
                            </div>
                            <div className={"col-md-7 credit_section_right_col"}>
                                <CreditScoreDataComponentMobile/>
                            </div>
                        </div>
                    </div>
                : ''
            }
        </>
    )
})
export default CreditScoreDataComponent;