import {IonCol, IonContent, IonPage, IonRow} from '@ionic/react';
import React, {useEffect} from "react";
import addPromoImg from "../../theme/img/loan_application.png";
import AdminMainHeaderComponent from "../../components/HeaderComponents/AdminMainHeaderComponent";
import AdminHomePageComponent from "../../components/AdminHomePageComponent";
import {useStoreState} from "../../provider/StateProvider";

const AdminDashboard = () => {

    const {actionToGetAllBankList,actionToGetAllBankLoanOfferList} = useStoreState();

    useEffect(()=>{
        actionToGetAllBankList();
        actionToGetAllBankLoanOfferList();
    },[])

    return (
        <IonPage>
            <AdminMainHeaderComponent/>
            <IonContent fullscreen>
                <div className="main_header_section_second_header admin_panel">
                    <div className="promotion_add_header_section">
                        <IonRow>
                            <IonCol className={"new_logo_promo_img_section"}>
                                <img className='promotion_add_header_section_img_1' src={addPromoImg}/>
                            </IonCol>
                            <IonCol>
                                <div className='promotion_header_text_section'>
                                    <span className='loan_text_span_1'>Check all data tables here</span><br></br><br></br>
                                    <span className='loan_text_span_2'>Easily check your data and edit update it.</span><br></br><br></br>
                                </div>
                            </IonCol>
                        </IonRow>
                    </div>
                </div>
                <AdminHomePageComponent/>
            </IonContent>
        </IonPage>
    );
};

export default AdminDashboard;
