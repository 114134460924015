import React from 'react';
import {IonCol, IonRow} from "@ionic/react";
import mobileApp from '../../theme/img/mobile-app.png'
import mobileAppScan from '../../theme/img/scan_app.png'

const DownloadAppSectionComponent = React.memo(function DownloadAppSectionComponent() {
    return (
        <div className='download_app_data_section_div'>
            <div className='download_app_data_section_div_inner_block'>
                <div className={"row"}>
                    <div className={"col-md-6"}>
                        <div className='download_app_data_heading'>Download Apna Finance Mobile App</div>
                        <ul className={"download_app_ul"}>
                            <li>Track your credit score all the time and stay financially healthy</li>
                            <li>Get exclusive Loans and Credit Card offers</li>
                            <li>Enjoy a seamless experience</li>
                        </ul>
                        <div className='download_app_data_heading_2'>Scan or click to Download App on your mobile</div>
                        <div className='download_app_data_image_scan_div'>  <img src={mobileAppScan}/></div>
                    </div>
                    <div className={"col-md-6"}>
                        <div className='download_app_data_img'>
                            <img src={mobileApp}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default DownloadAppSectionComponent;