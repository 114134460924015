import React from 'react';
import {IonHeader} from "@ionic/react";
import logoImg from "../../theme/img/logo.png";
import {  useHistory } from 'react-router-dom';
const SubHeaderComponent = React.memo(function SubHeaderComponent() {
    const history = useHistory();
    const goBack = ()=>{
        history.goBack();
    }
    return (
        <IonHeader className="ion-no-border">
            <div className={"main_header_section_header sub_header_section"}>
                <div className="main_header_section_header_inner_header">
                    <div className='logo_and_title'>
                        <img src={logoImg} className="site_logo_image"/>
                        <div className="logo_section">
                            <div className="first">apna</div>
                            <div className="second">Finance</div>
                        </div>
                    </div>
                    <div className={"right_side_sub_header"}>
                        <div onClick={goBack} className={"back_button"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.151 219.151"><path d="M109.576 219.151c60.419 0 109.573-49.156 109.573-109.576S169.995 0 109.576 0 .002 49.156.002 109.575s49.155 109.576 109.574 109.576zm0-204.151c52.148 0 94.573 42.426 94.574 94.575s-42.425 94.575-94.574 94.576-94.573-42.427-94.573-94.577S57.428 15 109.576 15zM94.861 156.507c2.929 2.928 7.678 2.927 10.606 0a7.5 7.5 0 0 0-.001-10.608l-28.82-28.819 83.457-.008a7.5 7.5 0 1 0-.001-15l-83.46.008 28.827-28.825a7.5 7.5 0 0 0 0-10.607 7.48 7.48 0 0 0-5.304-2.197c-1.919 0-3.838.733-5.303 2.196l-41.629 41.628c-1.407 1.406-2.197 3.313-2.197 5.303a7.51 7.51 0 0 0 2.198 5.305l41.627 41.624z"/></svg>
                            &nbsp;Go Back
                        </div>
                    </div>
                </div>
            </div>
        </IonHeader>
    )
})
export default SubHeaderComponent;