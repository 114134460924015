import React from 'react';
import {IonContent, IonPage} from "@ionic/react";
import SubHeaderComponent from "../../components/HeaderComponents/SubHeaderComponent";
import EditBankLoanOfferListComponent
    from "../../components/AddBankLoanWithInterestComponents/EditBankLoanOfferListComponent";

export const EditBankLoanOfferListDataPage=()=> {
    return (
        <IonPage>
            <SubHeaderComponent/>
            <IonContent fullscreen>
                <EditBankLoanOfferListComponent/>
            </IonContent>
        </IonPage>
    )
}