import React from "react";

const ArcComponent = (props) => {

    function toRadians(angle) {
        return angle * (Math.PI / 180);
    }

    let percentage = (props.score - 300) / 550;
    let angle;
    if (percentage > 0.65) {
        angle = 90 - percentage * 90;
    } else {
        angle = 45 - percentage * 45;
    }
    let d = toRadians(angle);
    let x, y;
    let cos = 100 * Math.cos(d);
    let sin = 100 * Math.sin(d);
    if (percentage > 0.65) {
        x = Math.abs(Math.max(cos, sin));
        y = Math.abs(Math.min(cos, sin));
    } else {
        x = Math.abs(Math.min(cos, sin));
        y = Math.abs(Math.max(cos, sin));
    }
    // this doesnt work properly.. geometry...
    return (
        <div id="arc">
            <div className="line" id="bad"></div>
            <div className="line" id="poor"></div>
            <div className="line" id="fair"></div>
            <div className="line" id="good"></div>
            <div className="line" id="excellent"></div>
            <div className="accent" id="low">
                300
            </div>
            <div className="accent" id="high">
                850
            </div>
            <div id="currentScoreContainer">
                <div id="flex column">
                    <span id="currentScore">{props?.score}</span>
                    <span id="dot" style={{ transform: `translate(${x}px, -${y}px)` }}></span>
                </div>
                <div>
                    <span id="currentScoreDetail">{props?.creditScoreText}</span>
                </div>
            </div>
        </div>
    );
}
export default ArcComponent;