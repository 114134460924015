import React from 'react';
import {IonContent, IonPage} from "@ionic/react";
import SubHeaderComponent from "../components/HeaderComponents/SubHeaderComponent";
import LoanEligibilityCheckFormComponent from "../components/LoanEligiblityComponents/LoanEligiblityCheckFormComponent";

export const LoanEligibilityCheckPage=()=> {
    return (
        <IonPage>
            <SubHeaderComponent/>
            <IonContent fullscreen>
               <LoanEligibilityCheckFormComponent/>
            </IonContent>
        </IonPage>
    )
}