import React from 'react';
import loanApplicationImg from "../theme/img/finance-sale.png";
import {useStoreState} from "../provider/StateProvider";

const BankLoanOfferListComponent = React.memo(function BankLoanOfferListComponent() {

    const {bankLoanOfferList} = useStoreState();

    const renderData = (offerArray)=>{
        const bankData = [];
        offerArray?.map((offer,key)=>{
            bankData.push(
                <div key={key} className={"loan_banks_list_data_loop"}>
                    <img className={"bank_image_section"} src={offer?.image_url} loading="lazy"/>
                    <div className={"bank_name_section"}>{offer?.name}</div>
                    <div className={"bank_interest_rate"}>Interest Rate : <b>{offer?.interest_rate}%</b></div>
                </div>
            )
        })
        return bankData;
    }

    return (
        <div className="main_header_section_second_header loan_page_application home_loan">
            <div className="promotion_add_header_section">
                <div className={"application_header_heading"}>
                    Bank Loan offer list
                </div>
                <div className="row">
                    <div className={"col-md-6 not_to_display_in_phone"}>
                        <div className={"new_logo_promo_img_section"}>
                            <img className='promotion_add_header_section_img_1' src={loanApplicationImg}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={"bank_main_list_section"}>
                            {(bankLoanOfferList !== undefined && Object?.keys(bankLoanOfferList).length) ?
                                <>
                                    {Object?.keys(bankLoanOfferList)?.map((key,index)=>(
                                        <div key={index} className={"bank_main_list_section_loop"}>
                                            <div className={"heading_section_for_loan_type"}>
                                                <div className={"loan_type_heading"}>{key?.toUpperCase()}</div>
                                                {(bankLoanOfferList[key] !== undefined && bankLoanOfferList[key]?.length) ?
                                                    <div className={"loan_banks_list_data_main_outer"}>
                                                        {renderData(bankLoanOfferList[key])}
                                                    </div>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default BankLoanOfferListComponent;