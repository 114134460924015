import {IonContent,IonPage} from '@ionic/react';
import React, {useEffect, useState} from "react";
import loginCover from '../theme/img/login_page_image_cover.png';
import LoginMobileNumberPage from "../components/LoginComponents/LoginMobileNumberPage";
import LoginOtpVerificationComponent from "../components/LoginComponents/LoginOtpVerificationComponent";
import {useHistory} from "react-router-dom";

const LoginSignupPage = () => {
    const [verifyLoginOtpRequest,setVerifyLoginOtpRequest] = useState(false);
    const [userEnteredMobileNumber,setUserEnteredMobileNumber] = useState('');
    const [userEnteredOtp,setUserEnteredOtp] = useState('');
    const history = useHistory();
    const goBack = ()=>{
        history.goBack();
    }
    useEffect(()=>{
        if(!verifyLoginOtpRequest){
            setUserEnteredMobileNumber('');
        }
    },[verifyLoginOtpRequest])
    return (
        <IonPage>
           <IonContent>
               <div className={"row"}>
                   <div className={"col-md-5 not_to_display_in_phone"}>
                       <div className={"login_page_left_side_container"}>
                           <div className={"image_login_cover_img"}>
                               <img src={loginCover}/>
                           </div>
                       </div>
                   </div>
                   {(verifyLoginOtpRequest) ?
                       <LoginOtpVerificationComponent userEnteredOtp={userEnteredOtp} setUserEnteredOtp={setUserEnteredOtp} userEnteredMobileNumber={userEnteredMobileNumber} verifyLoginOtpRequest={verifyLoginOtpRequest} setVerifyLoginOtpRequest={setVerifyLoginOtpRequest}/>
                       :
                       <LoginMobileNumberPage userEnteredMobileNumber={userEnteredMobileNumber} setUserEnteredMobileNumber={setUserEnteredMobileNumber} verifyLoginOtpRequest={verifyLoginOtpRequest} setVerifyLoginOtpRequest={setVerifyLoginOtpRequest}/>
                   }
               </div>
           </IonContent>
        </IonPage>
    );
};

export default LoginSignupPage;
