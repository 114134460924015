import React from 'react';
import {IonContent, IonPage} from "@ionic/react";
import SubHeaderComponent from "../components/HeaderComponents/SubHeaderComponent";
import LoanApplicationFormComponent from "../components/LoanApplicationComponents/LoanApplicationFormComponent";
import ApplicationSubmitPopupComponent from "../components/LoanApplicationComponents/ApplicationSubmitPopupComponent";

export const LoanApplicationFormPage=()=> {
    return (
        <IonPage>
            <SubHeaderComponent/>
            <IonContent fullscreen>
                <LoanApplicationFormComponent/>
            </IonContent>
            <ApplicationSubmitPopupComponent/>
        </IonPage>
    )
}