import {IonContent,IonPage} from '@ionic/react';
import React, {useEffect, useState} from "react";
import loginCover from '../theme/img/login_page_image_cover.png';
import mobileNumberLogo2 from "../theme/img/logo_mobile_img.png";
import {useStoreState} from "../provider/StateProvider";
import logoImg from "../theme/img/logo.png";

const AuthenticationScreen = () => {
    const [userEnteredAdminPassword,setUserEnteredAdminPassword] = useState('');
    const [loading,setLoading] = useState(false);

    const {actionToLoginUserByAdminPassword} = useStoreState();
    const callFunctionToValidateAdminPassword = async ()=>{
        if(loading) return;
        if(userEnteredAdminPassword?.trim()?.length){
            setLoading(true);
            const {response} = await actionToLoginUserByAdminPassword(userEnteredAdminPassword);
            if(response?.status){
                localStorage.setItem('adminAuthentication',true);
                window.location.reload();
            }else{
                alert('Not Valid Password');
                setUserEnteredAdminPassword('');
            }
            setTimeout(()=>{
                setLoading(false);
            })
        }
    }

    const actionToSetUserEnteredAdminPassword = (val)=>{
        console.log('val',val)
        setUserEnteredAdminPassword(val);
    }


    return (
        <IonPage>
            <IonContent>
                <div className={"row"}>
                    <div className={"col-md-5 not_to_display_in_phone"}>
                        <div className={"login_page_left_side_container"}>
                            <div className={"image_login_cover_img"}>
                                <img src={loginCover}/>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-7"}>
                        <div className={"login_page_right_side_container"}>
                            <div className={"login_page_site_logo_section"}>
                                <div className='logo_and_title'>
                                    <img src={logoImg} className="site_logo_image"/>
                                    <div className="logo_section">
                                        <div className="first">apna</div>
                                        <div className="second">Finance</div>
                                    </div>
                                </div>
                            </div>
                            <div className={"login_title_section"}>Admin Panel Login</div>
                            <div className={"login_title_section_mobile_number_image"}>
                                <img alt={'image'} src={mobileNumberLogo2}/>
                            </div>
                            <div className={"login_title_section_input_mobile"}>
                                <form autoComplete="off">
                                    <div className="form_group fullCon flag">
                                        <input className="custom_input admin"
                                               style={{textAlign:'center'}}
                                               type="password"
                                               onChange={(e)=>actionToSetUserEnteredAdminPassword(e.target.value)}
                                               placeholder="Admin password"
                                               autoFocus={true}
                                               autoComplete={'off'}
                                               value={userEnteredAdminPassword}
                                               name="mobileNo"/>
                                        <span className="_line p-R dB fullCon"></span>
                                    </div>
                                </form>
                            </div>
                            <div className={"login_title_section_input_mobile_button"}>
                                <button onClick={callFunctionToValidateAdminPassword} className={"login_button"}>
                                    {!loading ? 'Login' : 'Loading...'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default AuthenticationScreen;
