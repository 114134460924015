import React from 'react';
import contactUs from "../../theme/img/contact-us.png";
import aboutUs from "../../theme/img/about-us.png";
import {IonCol, IonRow} from "@ionic/react";
import { Swiper, SwiperSlide } from 'swiper/react';
import {A11y, Autoplay, FreeMode, Navigation, Pagination, Scrollbar} from "swiper";
// Import Swiper styles
import 'swiper/css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const FooterSliderComponent = React.memo(function FooterSliderComponent() {
    return (
        <Swiper className={"home_slide_show_slider_footer"}
                modules={[Navigation, Pagination, Scrollbar, A11y,Autoplay,FreeMode]}
                pagination={{ clickable: true }}>
            <SwiperSlide>
                <div className="footer_section_second_header first">
                    <div className="footer_add_header_section">
                        <IonRow>
                            <IonCol>
                                <div className={"footer_text_section"}>
                                    <span className={"footer_text_1"}>About us</span><br></br><br></br>
                                    <div className={"footer_text_2"}>
                                        Personal finance includes a range of activities, from purchasing financial products such as credit cards, insurance, mortgages, to various types of investments. Banking is also considered a component of personal finance because individuals use checking and savings accounts as well as online or mobile payment services such as PayPal and Venmo.
                                    </div><br></br><br></br>
                                </div>
                            </IonCol>
                        </IonRow>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="footer_section_second_header second">
                    <div className="footer_add_header_section">
                        <IonRow>
                            <IonCol>
                                <div className={"footer_text_section"}>
                                    <span className={"footer_text_1"}>Contact us</span><br></br><br></br>
                                    <div className={"footer_text_2"}>
                                        Personal finance includes a range of activities, from purchasing financial products such as credit cards, insurance, mortgages, to various types of investments. Banking is also considered a component of personal finance because individuals use checking and savings accounts as well as online or mobile payment services such as PayPal and Venmo.
                                    </div><br></br><br></br>
                                </div>
                            </IonCol>
                        </IonRow>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    )
})
export default FooterSliderComponent;