import React, {useEffect, useState} from 'react';
import logoImg from "../../theme/img/logo.png";
import {useStoreState} from "../../provider/StateProvider";

const LoginOtpVerificationComponent = React.memo(function LoginOtpVerificationComponent({setVerifyLoginOtpRequest,userEnteredMobileNumber,userEnteredOtp,setUserEnteredOtp}) {

    const timeInterval = null;
    const {
        actionToVerifyOtpToUserMobileNumber,
        actionToSendOtpVerificationToUserMobileNumber,
        callFunctionToStoreNewUserData
    } = useStoreState();
    let [counter,setCounter] = useState(20);
    let [otpError,setOtpError] = useState(false);
    const callFunctionToVerifyOtpInMobileNumber = ()=>{
        setVerifyLoginOtpRequest(true);
        if(userEnteredOtp?.trim()?.length === 4){
            const mobile = `+91${userEnteredMobileNumber}`;
            actionToVerifyOtpToUserMobileNumber(mobile,userEnteredOtp).then((data)=>{
                if(data?.response?.status){
                    if(data?.response?.count === 0){
                        let aliasArray = ['?'];
                        let columnArray = ['mobile'];
                        let valuesArray = [mobile];
                        let insertData = {alias:aliasArray,column:columnArray,values:valuesArray,tableName:'app_user'};
                        callFunctionToStoreNewUserData(insertData);
                    }
                    localStorage.setItem('userInfo',JSON.stringify({mobile:mobile}));
                    window.location.reload(true);
                }else{
                    setOtpError(true);
                    setUserEnteredOtp('');
                }
            });
        }
    }

    const resendOtpValue = ()=>{
        const mobile = `+91${userEnteredMobileNumber}`;
        actionToSendOtpVerificationToUserMobileNumber(mobile);
        resetCounterValue(20);
    }

    const resetCounterValue = (value)=>{
        setCounter(value);
        if(timeInterval !== null)
            clearInterval(timeInterval);

        setInterval(function(){
            if(counter >= 0) {
                setCounter(counter--);
            }else{
                clearInterval(timeInterval);
            }
        },1000)
    }
    useEffect(()=>{
        resetCounterValue(20);
    },[])
    return (
        <div className={"col-md-7"}>
            <div className={"login_page_right_side_container"}>
                <div className={"login_page_site_logo_section"}>
                    <div className='logo_and_title'>
                        <img src={logoImg} className="site_logo_image"/>
                        <div className="logo_section">
                            <div className="first">apna</div>
                            <div className="second">Finance</div>
                        </div>
                    </div>
                </div>
                <div className={"login_title_section"}>Verify Mobile Number</div>
                <div className={"login_title_section_sent_otp_message"}>
                    We have sent the OTP to {userEnteredMobileNumber}
                    <span className="clr-anchor TdecorN hand"><span onClick={()=>setVerifyLoginOtpRequest(false)} className="edit_icon">Edit</span></span>
                </div>
                {(counter > 0) ?
                    <div className={"login_title_section_resent_otp_div"}>Resend OTP {counter}s</div>
                    :
                    <div onClick={()=>resendOtpValue()} className={"login_title_section_resent_otp_div"}>Resend OTP</div>
                }
                <div className={"login_title_section_input_mobile "+(otpError ? 'error' : '')}>
                    <form autoComplete="off">
                        <div className="form_group fullCon flag">
                            <input className="custom_input otp_input"
                                   id="ssoOtp" autoComplete="off"
                                   pattern="[0-9]*"
                                   type="tel"
                                   onKeyUp={()=>setOtpError(false)}
                                   onChange={(e)=>setUserEnteredOtp(e.target.value)}
                                   placeholder="...."
                                   maxLength="4"
                                   validations="required,maxlen:4"
                                   value={userEnteredOtp}
                                   name="ssoOtp"/>
                            <span className="_line p-R dB fullCon"></span>
                        </div>
                    </form>
                    {(otpError) ? <div className={"not_valid"}>Not a valid OTP please resend and try again.</div>:''}
                </div>
                <div className={"login_title_section_input_mobile_button"}>
                    <button disabled={userEnteredOtp?.trim()?.length < 4 ? true : false} onClick={()=>callFunctionToVerifyOtpInMobileNumber()} className={"login_button"}>Verify & Login</button>
                </div>
                <div className={"login_title_section_term_of_use_section"}>
                    By logging in, you agree to the following
                </div>
                <div className={"login_title_section_term_of_use_section_2"}>
                    Credit Report Terms of Use and Terms of Use
                </div>
            </div>
        </div>
    )
})
export default LoginOtpVerificationComponent;