import Axios from 'axios';
import {
    createContext,
    useContext, useEffect, useState,
} from "react";
import cloneDeep from 'lodash/cloneDeep';
import CryptoJS from 'crypto-js';

export const StoreContext = createContext(null);
const api = Axios.create({
    baseURL: `https://apnafinances.com/api/`
})
export const StoreProvider = ({ children }) => {

    let [applicationSubmitPopupSuccess,setApplicationSubmitPopupSuccess] = useState({loading:false,message:'',body:'',isOpen:false});
    let [userSignInInfo,setUserSignInInfo] = useState( null);
    let [loanApplicationData,setLoanApplicationData] = useState( []);
    let [creditScoreData,setCreditScoreData] = useState( {});
    let [allBankListData,setAllBankListData] = useState( []);
    let [bankLoanOfferList,setBankLoanOfferList] = useState( {});

    useEffect(()=>{
        if(localStorage.getItem('userInfo')){
            const user = JSON.parse(localStorage.getItem('userInfo'));
            setUserSignInInfo(user);
        }
    },[])

    const actionToSendOtpVerificationToUserMobileNumber = async (mobileNumber)=>{
        const { data } = await api.post('loan/sendOtpVerificationSMSToNumber',{mobile:mobileNumber});
        return data;
    }
    const actionToVerifyOtpToUserMobileNumber = async (mobileNumber,otp)=>{
        const { data } = await api.post('loan/verifyOtpVerificationSMSToNumber',{mobile:mobileNumber,otp:otp});
        return data;
    }
    const actionToInsertBankLoanList = async (payload,resetBankOfferForm)=>{
        applicationSubmitPopupSuccess.isOpen = true;
        applicationSubmitPopupSuccess.loading = true;
        applicationSubmitPopupSuccess.message = 'Submitting bank loan form!';
        setApplicationSubmitPopupSuccess(cloneDeep(applicationSubmitPopupSuccess))


        let aliasArray = ['?','?','?'];
        let columnArray = ['bank','loan_type','interest_rate'];
        let valuesArray = [payload.bank,payload.loanType,payload.interestRate];
        let insertData = {alias:aliasArray,column:columnArray,values:valuesArray,tableName:'bank_loan_list'};
        callInsertDataFunction(insertData);

        setTimeout(function(){
            applicationSubmitPopupSuccess.isOpen = true;
            applicationSubmitPopupSuccess.loading = false;
            applicationSubmitPopupSuccess.message = 'Your bank form successfully submitted!';
            setApplicationSubmitPopupSuccess(cloneDeep(applicationSubmitPopupSuccess))
            resetBankOfferForm();
        },2000)
    }

    const actionToGetAllBankList = async ()=>{
        const { data } = await api.post('loan/actionToGetAllBankListApiCall');
        setAllBankListData(data?.response);
    }
    const actionToGetAllBankLoanOfferList = async ()=>{
        const { data } = await api.post('loan/getAllBankLoanOfferListApiCall');

        let bankData = {};

        data?.response?.map((bankOffer)=>{
            if(bankData[bankOffer?.loan_type] !== undefined){
                bankData[bankOffer.loan_type]?.push(bankOffer);
            }else{
                bankData[bankOffer.loan_type] = [bankOffer];
            }
        })

        setBankLoanOfferList(bankData);
    }
    const callFunctionToStoreNewUserData = (insertData)=>{
        callInsertDataFunction(insertData);
    }
    const callFunctionToGetCreditReportData = async (payload,history,resetApplicationForm)=>{
        let encryptedData = CryptoJS.AES.encrypt(JSON.stringify(payload), "appna12345678901000000").toString();
        const {data} = await api.post('loan/actionToGetUserCreditScoreApiCall',{payload:encryptedData});
        let responseData = data?.response;
        let decryptedBytes = CryptoJS.AES.decrypt(responseData, "appna12345678901000000");
        let plaintext = decryptedBytes.toString(CryptoJS.enc.Utf8);
        let dataFrom = JSON.parse(JSON.parse(plaintext));
        resetApplicationForm();
        setCreditScoreData(dataFrom?.CCRResponse);
        history.push('credit-score-page');
    }
    const callFunctionToGetCreditReportDataForLoanEligibilityCheck = async (payload,history,resetApplicationForm)=>{
        let encryptedData = CryptoJS.AES.encrypt(JSON.stringify(payload), "appna12345678901000000").toString();
        const {data} = await api.post('loan/actionToGetUserCreditScoreApiCall',{payload:encryptedData});
        let responseData = data?.response;
        let decryptedBytes = CryptoJS.AES.decrypt(responseData, "appna12345678901000000");
        let plaintext = decryptedBytes.toString(CryptoJS.enc.Utf8);
        let dataFrom = JSON.parse(JSON.parse(plaintext));
        resetApplicationForm();
        setCreditScoreData(dataFrom?.CCRResponse);
        history.push('loan-eligibility-page-data');
    }

    const callFunctionToSubmitApplyLoanRequestForm = (payload)=>{

        applicationSubmitPopupSuccess.isOpen = true;
        applicationSubmitPopupSuccess.loading = true;
        applicationSubmitPopupSuccess.message = 'Submitting your application form!';
        setApplicationSubmitPopupSuccess(cloneDeep(applicationSubmitPopupSuccess))


        let aliasArray = ['?','?','?','?','?','?','?','?','?','?','?','?'];
        let columnArray = ['name','email','mobile','city','dob','loan_amount','loan_taken_timeline','property_city','employment_status','primary_bank_name','gross_turn_over','loan_type'];
        let valuesArray = [payload.fullName,payload.emailAddress,payload.mobileNumber,payload.cityName,payload.dateOfBirth,
        payload.loanAmountRange,payload.loanTakingPlan,payload.propertyCity,payload.employmentStatus,payload.primaryBankName,payload?.grossTurnOver !== undefined ? payload.grossTurnOver : '',payload.loanType];
        let insertData = {alias:aliasArray,column:columnArray,values:valuesArray,tableName:'loan_application'};
        callInsertDataFunction(insertData);

        setTimeout(function(){
            applicationSubmitPopupSuccess.isOpen = true;
            applicationSubmitPopupSuccess.loading = false;
            applicationSubmitPopupSuccess.message = 'Your loan application form successfully submitted!';
            applicationSubmitPopupSuccess.body = 'We will verify your request and will get back to you.';
            setApplicationSubmitPopupSuccess(cloneDeep(applicationSubmitPopupSuccess))
        },2000)
    }

    const actionToLoginUserByAdminPassword = async (password)=>{
        const { data } = await api.post('loan/loginUserByAdminPassword',{password});
        return data;
    }
    const actionToGetAllLoanApplication = async (password)=>{
        const { data } = await api.post('loan/getAllLoanApplicationApiCall',{password});
        setLoanApplicationData(data?.response);
    }
    const logoutUserFormApp=()=>{
        localStorage.removeItem('userInfo');
        window.location.reload();
    }
    async function callInsertDataFunction(payload){
        const { data } = await api.post('loan/insertCommonApiCall',payload);
        return data;
    }

    const updateNewInterestData = (rate,id,key)=>{
        let bankLoanOfferListFinal = bankLoanOfferList[key];
        bankLoanOfferListFinal?.map((offer,index)=>{
            if(id === offer?.id){
                bankLoanOfferListFinal[index].interest_rate = rate;
            }
        })
        setBankLoanOfferList(cloneDeep(bankLoanOfferListFinal));
        let setData = `interest_rate = $1`;
        let values = [rate];
        let whereCondition = `id = ${id}`;
        let dataToSend = {column:setData,value:values,whereCondition:whereCondition,tableName:'bank_loan_list'}
        dispatch(commonUpdateFunction(dataToSend));
    }
    const updateBankLoanListInterestData = (rate,id,key)=>{

        if(bankLoanOfferList[key] !== undefined) {
            bankLoanOfferList[key]?.map((offer, index) => {
                if (id === offer?.id) {
                    bankLoanOfferList[key][index].interest_rate = rate;
                }
            })
            setBankLoanOfferList(cloneDeep(bankLoanOfferList));
            let setData = `interest_rate = ?`;
            let values = [Number(rate)];
            let whereCondition = `id = ${id}`;
            let dataToSend = {
                column: setData,
                value: values,
                whereCondition: whereCondition,
                tableName: 'bank_loan_list'
            }
            commonUpdateFunction(dataToSend);
        }
    }
    const deleteBankLoanListInterestData = (id,key)=>{
        if(bankLoanOfferList[key] !== undefined) {
            bankLoanOfferList[key]?.map((offer, index) => {
                if (id === offer?.id) {
                    bankLoanOfferList[key].splice(index, 1);
                }
            })
            setBankLoanOfferList(cloneDeep(bankLoanOfferList));
            callDeleteDataFunction({condition: `id = ${id} `, tableName: 'bank_loan_list'});
        }
    }
    async function commonUpdateFunction(payload){
        // const setData = `avatar=?`;
        // const whereCondition = `id = '${payload.id}'`;
        // const value = [fileName];
        // const dataToSend = {column: setData, value, whereCondition, tableName: 'app_user'};

        try {
            const { data } = await api.post('loan/updateCommonApiCall',payload);
            return data;
        } catch (error) {
            console.log(error,'error');
        }
    }

    async function callDeleteDataFunction(payload){
        //  dispatch(callDeleteDataFunction({ condition: `id = '${id}' `, tableName: 'app_user' }));
        try {
            const { data } = await api.post('loan/deleteCommonApiCall',payload);
            return data;
        } catch (error) {
            console.log(error,'error');
        }
    }


    return (
        <StoreContext.Provider
            value={{
                callFunctionToSubmitApplyLoanRequestForm,
                applicationSubmitPopupSuccess,
                setApplicationSubmitPopupSuccess,
                actionToSendOtpVerificationToUserMobileNumber,
                actionToVerifyOtpToUserMobileNumber,
                logoutUserFormApp,
                userSignInInfo,
                callFunctionToStoreNewUserData,
                actionToLoginUserByAdminPassword,
                actionToGetAllLoanApplication,
                loanApplicationData,
                callFunctionToGetCreditReportData,
                callFunctionToGetCreditReportDataForLoanEligibilityCheck,
                creditScoreData,
                actionToGetAllBankList,
                allBankListData,
                actionToInsertBankLoanList,
                bankLoanOfferList,
                actionToGetAllBankLoanOfferList,
                updateBankLoanListInterestData,
                deleteBankLoanListInterestData
            }}>
            {children}
        </StoreContext.Provider>
    );
};
export const useStoreState = () => useContext(StoreContext);
