import React from 'react';
import {IonContent, IonPage} from "@ionic/react";
import AddBankLoanWithInterestComponent
    from "../../components/AddBankLoanWithInterestComponents/AddBankLoanWithInterestComponent";
import SubHeaderComponent from "../../components/HeaderComponents/SubHeaderComponent";
import ApplicationSubmitPopupComponent
    from "../../components/LoanApplicationComponents/ApplicationSubmitPopupComponent";

export const AddBankLoanWithInterestPage =()=> {
    return (
        <IonPage>
            <SubHeaderComponent/>
            <IonContent fullscreen>
                <AddBankLoanWithInterestComponent/>
            </IonContent>
            <ApplicationSubmitPopupComponent/>
        </IonPage>
    )
}