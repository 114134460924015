import React,{useState} from 'react';
import $ from 'jquery';
import loanApplicationImg from "../../theme/img/personal_loan.png";
import {useStoreState} from "../../provider/StateProvider";
import { useParams } from 'react-router-dom';

const PersonalLoanComponent = React.memo(function PersonalLoanComponent() {

    const {loanType} = useParams();
    const [fullName,setFullName] = useState('');
    const [dateOfBirth,setDateOfBirth] = useState('');
    const [cityName,setCityName] = useState('');
    const [emailAddress,setEmailAddress] = useState('');
    const [mobileNumber,setMobileNumber] = useState('');
    const [loanAmountRange,setLoanAmountRange] = useState('');
    const [loanTakingPlan,setLoanTakingPlan] = useState('');
    const [grossTurnOver,setGrossTurnOver] = useState('');
    const [propertyCity,setPropertyCity] = useState('');
    const [employmentStatus,setEmploymentStatus] = useState('');
    const [primaryBankName,setPrimaryBankName] = useState('');

    const {callFunctionToSubmitApplyLoanRequestForm} = useStoreState();

    const resetApplicationForm = () =>{
        setFullName('');
        setDateOfBirth('');
        setCityName('');
        setEmailAddress('');
        setMobileNumber('');
        setLoanAmountRange('');
        setLoanTakingPlan('');
        setPropertyCity('');
        setEmploymentStatus('');
        setPrimaryBankName('');
        setGrossTurnOver('');
    }


    const applyLoanRequestForm = ()=>{
        if(validateLoanApplyForm()){
            let payload = {
                fullName,
                dateOfBirth,
                emailAddress,
                cityName,
                mobileNumber,
                loanAmountRange,
                loanTakingPlan,
                propertyCity,
                employmentStatus,
                primaryBankName,
                grossTurnOver,
                loanType
            }
            callFunctionToSubmitApplyLoanRequestForm(payload);
            resetApplicationForm();
        }
    }

    const validateLoanApplyForm =()=>{
        if(fullName?.trim()?.length === 0){
            return false;
        }else if(mobileNumber?.length !== 10){
            return false;
        }else if(dateOfBirth?.trim()?.length === 0){
            return false;
        }else if(cityName?.trim()?.length === 0){
            return false;
        }else if(emailAddress?.trim()?.length === 0){
            return false;
        }else if(loanAmountRange?.trim()?.length === 0){
            return false;
        }else if(loanTakingPlan?.trim()?.length === 0){
            return false;
        }else if(loanTakingPlan?.trim()?.length === 0){
            return false;
        }else if(employmentStatus?.trim()?.length === 0){
            return false;
        }else if(!loanType){
            return false;
        }else{
            return true;
        }
    }

    React.useEffect(()=>{
        $(function () {
            $('.js-example-basic-single').select2();
            $('.js-example-basic-single').on('select2:select', function (e) {
                if(e.target.name === 'bank-account'){
                    setPrimaryBankName(e.target.value)
                }else if(e.target.name === 'property-city'){
                    setPropertyCity(e.target.value)
                }else if(e.target.name === 'reside-city'){
                    setCityName(e.target.value)
                }
            });
        });
    },[])

    return (
        <div className="main_header_section_second_header loan_page_application personal_loan">
            <div className="promotion_add_header_section">
                <div className={"application_header_heading"}>
                    Get Personal Loan Easily
                </div>
                <div className="row">
                    <div className={"col-md-6"}>
                        <div className={"new_logo_promo_img_section"}>
                            <img className='promotion_add_header_section_img_1' src={loanApplicationImg}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='promotion_header_text_section_loan_page_form'>
                            <form autoComplete={"off"}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="first-name">Full name (as per pan card):</label>
                                            <input type="text" value={fullName} onChange={(e)=>setFullName(e.target.value)} className="form-control" placeholder="Enter Your Full Name" id="fname"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="dob">Date of birth (as per pan card):</label>
                                            <input type="date" value={dateOfBirth} onChange={(e)=>setDateOfBirth(e.target.value)} className="form-control date_of_birth" placeholder="Date Of Birth" id="dob"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="email">Email address:</label>
                                            <input type="email" value={emailAddress} onChange={(e)=>setEmailAddress(e.target.value)} className="form-control" placeholder="example@xyz.com" id="email"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="pwd">Where do you currently reside ?</label>
                                            <select onChange={(e)=>setCityName(e.target.value)} value={cityName} className="js-example-basic-single select form-control" name="reside-city">
                                                <option className={"initial_option"}  value="">Select currently reside</option>
                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                <option value="Assam">Assam</option>
                                                <option value="Bihar">Bihar</option>
                                                <option value="Chandigarh">Chandigarh</option>
                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                <option value="Dadar and Nagar Haveli">Dadar and Nagar Haveli</option>
                                                <option value="Daman and Diu">Daman and Diu</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Lakshadweep">Lakshadweep</option>
                                                <option value="Puducherry">Puducherry</option>
                                                <option value="Goa">Goa</option>
                                                <option value="Gujarat">Gujarat</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                <option value="Jharkhand">Jharkhand</option>
                                                <option value="Karnataka">Karnataka</option>
                                                <option value="Kerala">Kerala</option>
                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                <option value="Maharashtra">Maharashtra</option>
                                                <option value="Manipur">Manipur</option>
                                                <option value="Meghalaya">Meghalaya</option>
                                                <option value="Mizoram">Mizoram</option>
                                                <option value="Nagaland">Nagaland</option>
                                                <option value="Odisha">Odisha</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                                <option value="Sikkim">Sikkim</option>
                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                <option value="Telangana">Telangana</option>
                                                <option value="Tripura">Tripura</option>
                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                <option value="Uttarakhand">Uttarakhand</option>
                                                <option value="West Bengal">West Bengal</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="mobile">Mobile number:</label>
                                            <div className={"input-group"}>
                                                <span className="input-group-addon">+91</span>
                                                <input type="number" value={mobileNumber} onChange={(e)=>setMobileNumber(e.target.value)} className="form-control" placeholder="9999999999" id="mobile"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="Required Loan Amount">Required loan amount:</label>
                                            <select value={loanAmountRange} onChange={(e)=>setLoanAmountRange(e.target.value)} className="form-control select">
                                                <option  className={"initial_option"}  value="">Select loan amount</option>
                                                <option value={"Upto 15 Lacs"}>Upto 50 Thousand</option>
                                                <option value={"15 Lacs - 20 Lacs"}>50 Thousand - 1 Lacs</option>
                                                <option value={"20 Lacs - 30 Lacs"}>1 Lacs - 2 Lacs</option>
                                                <option value={"30 Las - 75 Lacs"}>2 Las -3 Lacs</option>
                                                <option value={"30 Las - 75 Lacs"}>3 Las -4 Lacs</option>
                                                <option value={"30 Las - 75 Lacs"}>4 Las -5 Lacs</option>
                                                <option value={"75 Lacs+"}>5 Lacs+</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="loan-plan">When are you planning to take Loan?</label>
                                            <select value={loanTakingPlan} onChange={(e)=>setLoanTakingPlan(e.target.value)} className="form-control select">
                                                <option  className={"initial_option"}  value="">Select the timeline</option>
                                                <option value="Within 30 days">Within 30 days</option>
                                                <option value="30-90 days">30-90 days</option>
                                                <option value="90-180 days">90-180 days</option>
                                                <option value="180+ days">180+ days</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="job">How are you currently employed?</label>
                                            <select value={employmentStatus} onChange={(e)=>setEmploymentStatus(e.target.value)} className="form-control select">
                                                <option  className={"initial_option"}  value="">Select employment</option>
                                                <option value="Within 30 days">Salaried</option>
                                                <option value="30-90 days">Self Employed Professional</option>
                                                <option value="90-180 days">Self Employed Business</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div onClick={applyLoanRequestForm} className={"submit_form_button"}>
                                    <button type={"button"} disabled={!validateLoanApplyForm()} className={"btn "+(validateLoanApplyForm ? '' : 'cursor-block')}>SUBMIT TO GET BEST OFFERS</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default PersonalLoanComponent;