import { IonCol, IonRow } from '@ionic/react';
import React from 'react';
import {  useHistory } from 'react-router-dom';
import {useStoreState} from "../provider/StateProvider";

const AdminHomePageComponent = React.memo(function HomePageComponent() {
    const history = useHistory();
    const openTabByType = (type)=>{
        history.push(type);
    }
    const {actionToGetAllLoanApplication} = useStoreState();
    React.useEffect(()=>{
        actionToGetAllLoanApplication();
    },[])
    return (
        <div className='home_page_main_content_section'>
            <div className='inner_section_block'>
                <div className='inner_section_block_heading'>
                    Data Tables
                </div>
                <IonRow>
                    <IonCol>
                        <div onClick={()=>openTabByType(`/admin/loan-application-data`)} className='inner_section_block_card_loop_list'>
                            <div className='svg_icon'>
                                <svg fill='#575184' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 108.47"><path d="M40 72.91c5.38 15.82 27.83 16.4 32.87 0a16.37 16.37 0 0 0 5 2.83 32.39 32.39 0 0 0-2.21 4.07 31.09 31.09 0 0 0 .46 26.43H0C2 96.44.75 88.13 9.92 83 16.47 79.34 34 78.26 40 72.91zm82.06 23a5.66 5.66 0 0 1 .84 2.9c0 5.35-8.28 9.69-18.48 9.69s-18.47-4.34-18.47-9.69a5.72 5.72 0 0 1 .84-2.9c2.35 3.94 9.36 6.79 17.63 6.79s15.29-2.85 17.64-6.79zm-11-24.4l-.22 2.41-9.07-.79a8.29 8.29 0 0 1 1.56 3.26l-2.19-.2a7.06 7.06 0 0 0-1-2.13 4.33 4.33 0 0 0-1.88-1.69l.17-2 12.61 1.09zm-6.68-3.76c8.39 0 15.19 2.67 15.19 6s-6.8 6-15.19 6-15.18-2.68-15.18-6 6.8-6 15.18-6zm0-2c10.2 0 18.48 4.34 18.48 9.69s-8.28 9.68-18.48 9.68-18.47-4.34-18.47-9.68 8.27-9.69 18.47-9.69zm17.7 18.78a5.56 5.56 0 0 1 .78 2.78c0 5.34-8.28 9.68-18.48 9.68s-18.45-4.35-18.45-9.69a5.34 5.34 0 0 1 .78-2.78c2.27 4 9.33 6.9 17.69 6.9s15.42-2.92 17.7-6.9zM35.87 40.15a5 5 0 0 0-2.56.67 2 2 0 0 0-.73.85 2.88 2.88 0 0 0-.24 1.33c0 1.54.85 3.56 2.42 5.89h0 0l5.1 8.11c2 3.24 4.18 6.55 6.83 9a13.88 13.88 0 0 0 9.74 3.92 14.36 14.36 0 0 0 10.31-4.09c2.74-2.56 4.91-6.08 7-9.59l5.72-9.43c1.07-2.44 1.46-4.07 1.21-5-.14-.57-.77-.85-1.85-.9h-.69-.8a1.58 1.58 0 0 1-.44 0 7.59 7.59 0 0 1-1.57-.08l2-8.68C62.77 34.44 51.89 23.63 36.5 30l1.12 10.23a8.32 8.32 0 0 1-1.75-.07zm44.95-1.82a3.66 3.66 0 0 1 2.68 2.77c.41 1.6 0 3.85-1.4 6.94h0l-.08.17-5.79 9.54c-2.23 3.67-4.5 7.36-7.52 10.19a17 17 0 0 1-12.27 4.86 16.65 16.65 0 0 1-11.68-4.67 45.54 45.54 0 0 1-7.33-9.56l-5.09-8.08a14.85 14.85 0 0 1-2.88-7.38 5.9 5.9 0 0 1 .54-2.64 5 5 0 0 1 1.75-2 5.67 5.67 0 0 1 1.23-.62 129.51 129.51 0 0 1-.24-14.53 19 19 0 0 1 .58-3.32A19.43 19.43 0 0 1 41.9 9.05a27.17 27.17 0 0 1 7.2-3.2c1.61-.46-1.38-5.62.3-5.79C57.47-.76 70.52 6.6 76.16 12.7c2.82 3.06 4.58 7.09 5 12.45l-.31 13.18z" fillRule="evenodd"/></svg>
                            </div>
                            <div className='footer_text_section'>
                                Loan Form DataTable
                            </div>
                            <div className='footer_text_section_sub_text'>
                                Click here to check application data
                            </div>
                        </div>
                    </IonCol>
                    <IonCol>
                        <div onClick={()=>openTabByType(`/admin/add-bank-loan-and-interest-page`)} className='inner_section_block_card_loop_list'>
                            <div className='svg_icon'>
                                <svg fill='#fc5c00' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.749 12l1.104-1.908a1 1 0 0 0-.365-1.366l-1.91-1.104v-2.2a1 1 0 0 0-1-1h-2.199l-1.103-1.909a1.008 1.008 0 0 0-.607-.466.993.993 0 0 0-.759.1L12 3.251l-1.91-1.105a1 1 0 0 0-1.366.366L7.62 4.422H5.421a1 1 0 0 0-1 1v2.199l-1.91 1.104a.998.998 0 0 0-.365 1.367L3.25 12l-1.104 1.908a1.004 1.004 0 0 0 .364 1.367l1.91 1.104v2.199a1 1 0 0 0 1 1h2.2l1.104 1.91a1.01 1.01 0 0 0 .866.5 1 1 0 0 0 .501-.135l1.908-1.104 1.91 1.104a1.001 1.001 0 0 0 1.366-.365l1.103-1.91h2.199a1 1 0 0 0 1-1v-2.199l1.91-1.104a1 1 0 0 0 .365-1.367L20.749 12zM9.499 6.99a1.5 1.5 0 1 1-.001 3.001 1.5 1.5 0 0 1 .001-3.001zm.3 9.6l-1.6-1.199 6-8 1.6 1.199-6 8zm4.7.4a1.5 1.5 0 1 1 .001-3.001 1.5 1.5 0 0 1-.001 3.001z"/></svg>
                            </div>
                            <div className='footer_text_section'>
                                Add New Bank Loan Offer
                            </div>
                            <div className='footer_text_section_sub_text'>
                                Click here to add bank with loan offer
                            </div>
                        </div>
                    </IonCol>
                    <IonCol>
                        <div onClick={()=>openTabByType(`/admin/edit-bank-loan-and-interest-page`)} className='inner_section_block_card_loop_list'>
                            <div className='svg_icon'>
                                <svg fill='#2196f3' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 612"><path d="M239.486 368.979c3.389 3.388 7.828 5.081 12.267 5.081s8.88-1.695 12.27-5.081L447.32 185.683c6.775-6.777 6.775-17.762 0-24.536s-17.762-6.775-24.538 0L239.486 344.441c-6.775 6.777-6.775 17.761 0 24.538z"/><path d="M604.149 110.573c-6.702-20.057-21.54-41.957-42.922-63.333C539.68 25.69 506.868 0 472.925 0c-15.226 0-29.255 5.354-40.679 15.501a17.25 17.25 0 0 0-1.7 1.492l-65.823 65.821H20.838c-9.582 0-17.35 7.769-17.35 17.35V594.65c0 9.582 7.769 17.35 17.35 17.35h494.485c9.582 0 17.35-7.769 17.35-17.35V240.19c0-1.081-.113-2.134-.302-3.161l57.622-57.623.344-.343 1.138-1.138a17.07 17.07 0 0 0 1.47-1.673c15.779-17.698 19.657-40.372 11.204-65.679zm-368.01 359.211l-122.416 35.331 27.797-129.951 94.619 94.62zm31.738-17.337L156.023 340.592l260.97-260.974 111.856 111.856-260.972 260.973zm157.568 60.022H213.384l56.919-16.428c2.818-.814 5.383-2.328 7.458-4.401l220.211-220.211V577.3H38.188V117.515h291.836L118.818 328.723a17.35 17.35 0 0 0-4.699 8.64L73.73 526.188a17.35 17.35 0 0 0 5.137 16.321 17.36 17.36 0 0 0 11.833 4.659h334.745m141.045-393.4c-.189.202-.373.409-.551.619l-.124.123-.181.178-12.248 12.246L441.531 55.08l12.53-12.53c.217-.184.432-.373.641-.571 5.315-4.965 11.107-7.278 18.224-7.278 16.963 0 40.208 13.513 63.767 37.078 12.904 12.902 53.193 56.95 29.797 81.989z"/></svg>
                            </div>
                            <div className='footer_text_section'>
                                Edit Bank Loan Offer
                            </div>
                            <div className='footer_text_section_sub_text'>
                                Click here to edit bank with loan offer
                            </div>
                        </div>
                    </IonCol>
                </IonRow>
            </div>
        </div>
    )
})
export default AdminHomePageComponent;