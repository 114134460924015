import React, {useEffect, useState} from 'react';
import loanApplicationImg from "../../theme/img/personal_loan.png";
import {useStoreState} from "../../provider/StateProvider";

const EditBankLoanOfferListComponent = React.memo(function EditBankLoanOfferListComponent() {

    const {bankLoanOfferList,updateBankLoanListInterestData,deleteBankLoanListInterestData} = useStoreState();
    const [interestEditMode,setInterestEditMode] = useState({});
    const [interestNewValue,setInterestNewValue] = useState(0);

    useEffect(()=>{
        if(interestEditMode?.interest_rate){
            setInterestNewValue(interestEditMode?.interest_rate);
        }
    },[])

    const callFunctionToSetInterestEditMode = (offer)=>{
        setInterestEditMode(offer);
        setInterestNewValue(offer?.interest_rate);
    }

    const callFunctionToUpdateNewInterestData = (id,indKey)=>{
        updateBankLoanListInterestData(interestNewValue,id,indKey);
        setInterestEditMode({});
        setInterestNewValue(0);
    }
    const callFunctionToDeleteBankLoanListInterestData = (id,indKey)=>{
        deleteBankLoanListInterestData(id,indKey);
    }

    const renderData = (offerArray,indKey)=>{
        const bankData = [];
        offerArray?.map((offer,key)=>{
            bankData.push(
                <div key={key} className={"loan_banks_list_data_loop"}>
                    <img className={"bank_image_section"} src={offer?.image_url} loading="lazy"/>
                    <div className={"bank_name_section"}>{offer?.name}</div>
                    {(interestEditMode?.id === offer?.id) ?
                        <div className={"bank_interest_rate_edit"}>
                            <input autoFocus={true}
                                   type={"number"}
                                   onChange={(e)=>setInterestNewValue(e.target.value)}
                                   value={interestNewValue}
                            />
                        </div>
                        :
                        <div className={"bank_interest_rate"}>
                            Interest Rate : <b>{offer?.interest_rate}%</b>
                        </div>
                    }
                    {(interestEditMode?.id !== offer?.id) ?
                        <div className={"edit_delete_interest_rate_button"}>
                            <button onClick={() => callFunctionToSetInterestEditMode(offer)} className={"edit_interest_rate__icon"}>
                                Edit
                            </button>
                            <button onClick={() => callFunctionToDeleteBankLoanListInterestData(offer?.id, indKey)} className={"edit_delete_interest_rate__icon"}>
                                Delete
                            </button>
                        </div>
                        :
                        <div className={"edit_delete_interest_rate_button"}>
                            <button onClick={() => callFunctionToUpdateNewInterestData(offer?.id,indKey)} className={"edit_interest_rate__icon"}>
                                Save
                            </button>
                        </div>
                    }

                </div>
            )
        })
        return bankData;
    }

    return (
        <div className="main_header_section_second_header loan_page_application home_loan">
            <div className="promotion_add_header_section">
                <div className={"application_header_heading"}>
                   Edit Bank Loan offer list
                </div>
                <div className="row">
                    <div className={"col-md-6 not_to_display_in_phone"}>
                        <div className={"new_logo_promo_img_section"}>
                            <img className='promotion_add_header_section_img_1' src={loanApplicationImg}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={"bank_main_list_section"}>
                            {(bankLoanOfferList !== undefined && Object?.keys(bankLoanOfferList).length) ?
                                <>
                                    {Object?.keys(bankLoanOfferList)?.map((key,index)=>(
                                        <div key={index} className={"bank_main_list_section_loop"}>
                                            <div className={"heading_section_for_loan_type"}>
                                                <div className={"loan_type_heading"}>{key?.toUpperCase()}</div>
                                                {(bankLoanOfferList[key] !== undefined && bankLoanOfferList[key]?.length) ?
                                                    <div className={"loan_banks_list_data_main_outer"}>
                                                        {renderData(bankLoanOfferList[key],key)}
                                                    </div>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </>
                                : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default EditBankLoanOfferListComponent;