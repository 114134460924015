import React, {useEffect} from 'react';
import { Redirect,Route } from 'react-router-dom';
import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './theme/css/style.css';
import './theme/css/responsive.css';
import {StoreProvider, useStoreState} from "./provider/StateProvider";
import {LoanApplicationFormPage} from "./pages/LoanApplicationFormPage";
import {isAdminLogin, isLogin} from './middlewear/auth';
import LoginSignupPage from "./pages/LoginSignupPage";
import LoanCalculatorPage from "./pages/LoanCalculatorPage";
import AdminDashboard from "./pages/admin/AdminDashboard";
import LoanApplicationDataPage from "./pages/admin/LoanApplicationDataPage";
import AuthRoute from "./hooks/AuthRoute.js";
import AuthenticationScreen from "./pages/AuthenticationScreen";
import {CheckCreditPage} from "./pages/CheckCreditPage";
import CreditScoreDataPage from "./pages/CreditScoreDataPage";
import {LoanEligibilityCheckPage} from "./pages/LoanEligibilityCheckPage";
import {LoanEligibilityDataPage} from "./components/LoanEligiblityComponents/LoanEligibilityDataPage";
import {BankLoanOfferListPage} from "./pages/BankLoanOfferListPage";
import {AddBankLoanWithInterestPage} from "./pages/admin/AddBankLoanWithInterestPage";
import {EditBankLoanOfferListDataPage} from "./pages/admin/EditBankLoanOfferListDataPage";

setupIonicReact();

const PrivateRoutes = () => {
    const {actionToGetAllBankLoanOfferList}:any = useStoreState();

    useEffect(()=>{
        actionToGetAllBankLoanOfferList();
    },[])
    const userAuth = localStorage.getItem('adminAuthentication');
    return (
        <IonReactRouter>
            <Route path="/loan-application/:loanType" component={LoanApplicationFormPage} />
            <Route path="/home" component={Home} />
            <Route path="/loan-calculator" component={LoanCalculatorPage} />
            <Route path="/check-civil-report" component={CheckCreditPage} />
            <Route path="/credit-score-page" component={CreditScoreDataPage} />
            <Route path="/" exact={true} component={Home} />
            <Route path="/login-signup" render={() => <Redirect to="/home"/>}/>
            <Route path="/bank-loan-offer-list-page" component={BankLoanOfferListPage}/>
            <AuthRoute component={AuthenticationScreen} userAuth={userAuth} currentpath={window.location.pathname} path="/admin"/>
        </IonReactRouter>
    );
};

const PublicRoutes = () => {
    const {actionToGetAllBankLoanOfferList}:any = useStoreState();

    useEffect(()=>{
        actionToGetAllBankLoanOfferList();
    },[])
    const userAuth = localStorage.getItem('adminAuthentication');
    return (
        <IonReactRouter>
            <Route path="/loan-application/:loanType" component={LoanApplicationFormPage} />
            <Route path="/home" component={Home} />
            <Route path="/loan-calculator" component={LoanCalculatorPage} />
            <Route path="/check-civil-report" component={CheckCreditPage} />
            <Route path="/credit-score-page" component={CreditScoreDataPage} />
            <Route path="/" exact={true} component={Home} />
            <Route path="/login-signup" component={LoginSignupPage}/>
            <Route path="/check-loan-eligibility" component={LoanEligibilityCheckPage}/>
            <Route path="/loan-eligibility-page-data" component={LoanEligibilityDataPage}/>
            <Route path="/bank-loan-offer-list-page" component={BankLoanOfferListPage}/>
            <Route path="/admin/edit-bank-loan-and-interest-page" component={EditBankLoanOfferListDataPage}/>
            <AuthRoute component={AuthenticationScreen} userAuth={userAuth} currentpath={window.location.pathname} path="/admin"/>
        </IonReactRouter>
    );
};
const AdminPublicRoutes = () => {
    const {actionToGetAllBankLoanOfferList}:any = useStoreState();

    useEffect(()=>{
        actionToGetAllBankLoanOfferList();
    },[])
    return (
        <IonReactRouter>
            <Route path="/loan-application/:loanType" component={LoanApplicationFormPage} />
            <Route path="/home" component={Home} />
            <Route path="/loan-calculator" component={LoanCalculatorPage} />
            <Route path="/check-civil-report" component={CheckCreditPage} />
            <Route path="/credit-score-page" component={CreditScoreDataPage} />
            <Route path="/" exact={true} component={Home} />
            <Route path="/login-signup" component={LoginSignupPage}/>
            <Route path="/check-loan-eligibility" component={LoanEligibilityCheckPage}/>
            <Route path="/admin" component={AdminDashboard}/>
            <Route path="/admin/admin-dashboard" exact={true} component={AdminDashboard}/>
            <Route path="/admin/loan-application-data" exact={true} component={LoanApplicationDataPage}/>
            <Route path="/admin/add-bank-loan-and-interest-page" exact={true} component={AddBankLoanWithInterestPage}/>
            <Route path="/bank-loan-offer-list-page" component={BankLoanOfferListPage}/>
            <Route path="/admin/edit-bank-loan-and-interest-page" component={EditBankLoanOfferListDataPage}/>
        </IonReactRouter>
    );
};

const App: React.FC = () => {
    return (
        <IonApp>
            <StoreProvider>
                {(isAdminLogin()) ? <AdminPublicRoutes/> : (isLogin()) ? <PrivateRoutes/> : <PublicRoutes/>}
            </StoreProvider>
        </IonApp>
    )
}

export default App;
